import { isNil } from 'lodash-es';

import { IQueryParams } from '@locumsnest/core/src/lib/interfaces/persistence-service';

import { IUrlTags } from './../interfaces/persistence-service';

export class UrlHelpers {
  static setParentFrameURLTag(tag: string) {
    try {
      const path = window.name;
      const url = new URL(path);

      url.hash = tag;
      window.top.location.href = url.toString();
    } catch (e) {
      // prevent crash when not in iframe
    }
  }

  static setURLTag(urlTags: IUrlTags) {
    let tag = '';

    for (const urlTag in urlTags) {
      if (urlTags[urlTag] !== null) tag += urlTag + '' + urlTags[urlTag] + '&';
    }

    UrlHelpers.setParentFrameURLTag(
      encodeURI(tag[tag.length - 1] === '&' ? tag.substring(0, tag.length - 1) : tag),
    );
  }

  static filterNilValues(arr) {
    return arr.filter((v) => !isNil(v));
  }

  static addQueryParams(
    filters: IQueryParams,
    key: string,
    value: IQueryParams[keyof IQueryParams],
    filterNil = false,
  ): IQueryParams {
    if (Array.isArray(value) && filterNil) {
      value = this.filterNilValues(value);
    }

    if (
      (!Array.isArray(value) && !isNil(value) && value !== '') ||
      (Array.isArray(value) && value.length > 0)
    ) {
      filters[key] = value;
    }

    return filters;
  }

  static addUrlTags(url: IUrlTags, key: string, value: string): IUrlTags {
    if (value) {
      url[key] = value;
    }

    return url;
  }
}
