import moment from 'moment-timezone';

import { Time } from './time';

export class Calendar {
  static getDateForQueryParam(date: Date, isStartDate?: boolean): string {
    const year: number = date.getFullYear();
    const month: number = date.getMonth();
    const days: number = isStartDate ? 1 : moment([year, month, 1]).daysInMonth();
    return Time.formatDBDate([year, month, days]);
  }
}
