import moment, { MomentInput } from 'moment-timezone';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  TIME_SEC_FORMAT,
} from '@locumsnest/core/src/lib/types/constants';

import {
  DATE_FORMAT_DB,
  DATE_FORMAT_NO_YEAR,
  DATE_FORMAT_NO_YEAR_WITH_DAY,
  DATE_TIME_PICKER_FORMAT,
  DATE_TIME_SERVER_ACCEPT_FORMAT,
  TIME_DATE_FORMAT,
} from '../types/constants';
import { IDateTimePeriod } from './interfaces';

export class Time {
  // because this is a static Class (currently absent in TS)
  private constructor() {
    // in case we convert it to a factory or singleton by accident
    throw new Error('Static class should not get instantiated');
  }

  /**
   * Returns next time after timestamp
   * @param timestamp {number} timestamp the expected time is relative to
   * @param time {string} the time is a string in the format HH:mm
   */
  static next(timestamp: number = Date.now(), time: string) {
    const relativeTime = moment(timestamp);
    const [hour, minute] = time.split(':').map((d) => parseInt(d, 10));
    const newTime = moment(timestamp).set({ hour, minute });
    if (newTime.isAfter(relativeTime)) return +newTime;
    return +moment(timestamp).add(1, 'days').set({ hour, minute });
  }
  static getMomentDateTime(date: string, time: string) {
    return moment(date + ' ' + time, DATE_TIME_FORMAT, true);
  }
  static splitDateTime(dateTime: MomentInput, format = DATE_TIME_FORMAT) {
    const date = moment(dateTime, format);
    return {
      date: date.format(DATE_FORMAT),
      time: date.format(TIME_FORMAT),
    };
  }
  static formatTime(time) {
    return moment(time, TIME_FORMAT).format(TIME_FORMAT);
  }

  static formatDateNoYear(date: Date): string {
    return moment(date).format(DATE_FORMAT_NO_YEAR);
  }

  static formatDateNoYearWithDay(date: Date): string {
    return moment(date).format(DATE_FORMAT_NO_YEAR_WITH_DAY);
  }

  static formatDate(date?: MomentInput, formatDate = DATE_FORMAT): string {
    return moment(date).format(formatDate);
  }

  static formatDateToDateTimeFilter<T extends string = 'start_time'>(
    date: MomentInput,
    startTimeKey?: T,
    dateFormat?: string,
  ): { [key in `${T}__gte` | `${T}__lt`]: string };
  static formatDateToDateTimeFilter<T extends string = 'start_time'>(
    date: MomentInput,
    timeKey = 'start_time',
    dateFormat = DATE_TIME_SERVER_ACCEPT_FORMAT,
  ): { [key in `${T}__gte` | `${T}__lt`]: string } {
    const startTime = moment(date).startOf('day');
    const endTime = startTime.clone().add(1, 'day');
    return {
      [`${timeKey}__gte`]: moment(startTime).format(dateFormat),
      [`${timeKey}__lt`]: moment(endTime).format(dateFormat),
    } as { [key in `${T}__gte` | `${T}__lt`]: string };
  }

  static formatDBDate(date?: MomentInput) {
    return Time.formatDate(date, DATE_FORMAT_DB);
  }

  static formatISODate(date?: MomentInput) {
    return Time.formatDate(date, DATE_TIME_SERVER_ACCEPT_FORMAT);
  }

  static formatDateFromDatePickerFormat(date: string, formatDate = DATE_FORMAT): string {
    const formattedDate = this.formatDate(this.getDateFromDatePickerFormat(date), formatDate);
    return moment(formattedDate, formatDate).isValid() ? formattedDate : date;
  }

  static formatDateTime(date: Date | string): string {
    return moment(date).format(DATE_TIME_FORMAT);
  }

  static formatDateByFormat(date: Date | string | number, format: string) {
    return moment(date).format(format);
  }

  static formatDateTimePickerFormat(date: MomentInput): string {
    return moment(date).format(DATE_TIME_PICKER_FORMAT);
  }

  static formatTimeDate(date: Date, format = TIME_DATE_FORMAT): string {
    return moment(date).format(format);
  }

  static getDuration(start: MomentInput, end: MomentInput): number {
    return moment.duration(moment(end).diff(start)).asHours();
  }

  static getDurationText(start: Date | number, end: Date | number): string {
    const duration = this.getDurationAsMinutes(start, end);
    return Time.getDurationTextByMinutes(duration);
  }

  static getDurationTextIncludingMinutes(start: Date | number, end: Date | number) {
    const duration = this.getDurationAsMinutes(start, end);
    return Time.getDurationTextByMinutesIncludingMinutes(duration);
  }

  static getDurationAsSeconds(start: Date | number, end: Date | number) {
    return moment.duration(moment(end).diff(start)).asSeconds();
  }

  static getDifferenceInDaysFromNow(date: Date | string) {
    return moment(date).diff(moment(), 'days');
  }

  static getDurationAsMinutes(start: Date | number, end: Date | number) {
    return moment.duration(moment(end).diff(start)).asMinutes();
  }

  static getDurationAsHours(start: Date | number | string, end: Date | number | string) {
    return moment.duration(moment(end).diff(start)).asHours();
  }

  static durationDiffInMinutes(
    firstPeriod: IDateTimePeriod<number | Date>,
    secondPeriod: IDateTimePeriod<number | Date>,
  ) {
    return (
      this.getDurationAsMinutes(firstPeriod.start, firstPeriod.end) -
      this.getDurationAsMinutes(secondPeriod.start, secondPeriod.end)
    );
  }

  static formatDbDateFromDatePicker(date: string): string {
    return this.formatDate(moment(date, DATE_FORMAT).toDate(), DATE_FORMAT_DB);
  }

  static isDayToday(templateLastUpdatedDate: string) {
    return moment(templateLastUpdatedDate).isSame(moment(), 'day');
  }

  static getDurationTextBySeconds = (durationInSeconds: number): string =>
    Time.getDurationTextByMinutes(durationInSeconds / 60);

  static getDurationTextByMinutes = (durationInMinutes: number): string => {
    const minutes = Math.round(durationInMinutes % 60);
    const hours = Math.floor(durationInMinutes / 60);
    const hourTxt = `${hours} hour${hours === 1 ? '' : 's'}`;
    const minTxt = `${minutes} min${minutes === 1 ? '' : 's'}`;

    if (hours === 0) {
      return minTxt;
    }

    if (minutes === 0) {
      return hourTxt;
    }

    return `${hourTxt} and ${minTxt}`;
  };
  static getDurationTextByMinutesIncludingMinutes = (durationInMinutes: number): string => {
    const minutes = Math.round(durationInMinutes % 60);
    const hours = Math.floor(durationInMinutes / 60);

    return `${String(hours).padStart(2, '0')} hr ${String(minutes).padStart(2, '0')} mins`;
  };

  static getDateFromDatePickerFormat(date: string, formatDate = DATE_FORMAT): Date {
    return moment(date, formatDate).toDate();
  }

  static getMomentFromDatePickerFormat(date: string, formatDate = DATE_FORMAT): moment.Moment {
    return moment(date, formatDate);
  }

  static getMomentFromDateTimePickerFormat(
    date: string,
    formatDate = DATE_TIME_PICKER_FORMAT,
  ): moment.Moment {
    return moment(date, formatDate);
  }

  static getDurationFullTextByMinutes = (durationInMinutes: number): string => {
    const minutes = durationInMinutes % 60;
    const hours = Math.floor(durationInMinutes / 60);

    return hours + ' hour(s) and ' + minutes + ' mins';
  };

  static todaysDate() {
    return moment().format(DATE_FORMAT);
  }

  static agoPickerDate(amount?: moment.DurationInputArg1, unit?: moment.DurationInputArg2) {
    return moment().subtract(amount, unit).format(DATE_FORMAT);
  }

  static roundToClosestMinute(dateTime: moment.Moment) {
    const startOfMinute = dateTime.clone().startOf('minute');
    const remainder = +startOfMinute - +dateTime;
    if (remainder >= 30 * 1000) {
      return dateTime.clone().endOf('minute');
    }
    return startOfMinute;
  }

  static toTimeStamp(date, time) {
    const dt = moment(date + ' ' + time, DATE_TIME_FORMAT);
    return dt.unix() * 1000;
  }

  static getDate(date?: Date | string | number): Date {
    return moment(date).toDate();
  }

  static isTimeValid(time: string) {
    const timeRegex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      `^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$`,
    );
    return timeRegex.test(time);
  }

  static isValidDate(date: string) {
    return !isNaN(Date.parse(date));
  }

  static isBetween(current: Date | string, start: Date | string, end: Date | string): boolean {
    return moment(current).isBetween(start, end);
  }

  static addDaysAndGetMinutes(date: number, daysToAdd: number): number {
    return moment(date).add(daysToAdd, 'days').toDate().getTime();
  }

  static convertSecondsToHours(seconds: number): number {
    return seconds / 3600;
  }

  static getDateFromDateAndTime(date: Date, time: string): Date {
    return moment(
      `${this.formatDate(date)} ${time}`,
      `${DATE_FORMAT} ${TIME_SEC_FORMAT}`,
      true,
    ).toDate();
  }

  static isAfter(date: Date, target: Date): boolean {
    return moment(date).isAfter(target);
  }

  static isBefore(date: Date | string, target: Date | string): boolean {
    return moment(date).isBefore(target);
  }

  static getMoment(time?: MomentInput, formatDate?: string) {
    if (time) {
      return formatDate ? moment(time, formatDate) : moment(time);
    }

    return moment();
  }

  static fromNow(date: string | Date | number) {
    return moment(date).fromNow();
  }

  static isWithinAnHour(date: Date): boolean {
    return moment(date).add(1, 'hours').isAfter(moment());
  }

  static isAfterAnHour(date: Date): boolean {
    return moment(date).add(1, 'hours').isBefore(moment());
  }

  static getDateTime(date: Date | string, time: string) {
    const [hours, minutes] = time.split(':');
    return moment(date).set('hours', +hours).set('minutes', +minutes);
  }

  static isValidMoment(date: moment.MomentInput, inputFormat?: string): boolean {
    return inputFormat ? moment(date, inputFormat, true).isValid() : moment(date).isValid();
  }

  static getTimeFromDateAndSetToOtherDate(date: string, target: string): Date {
    const time = moment(date).format(TIME_FORMAT);
    const toDate = Time.formatDate(Time.getMomentFromDatePickerFormat(target));
    const fullDate = toDate + 'T' + time;
    return Time.getDateFromDatePickerFormat(fullDate, DATE_FORMAT + TIME_FORMAT);
  }
}
